.Albums-album-cover {
  margin-bottom: 10px;
  flex-grow: 1;
}

.Albums-album-cover .MuiFab-extended {
  width: auto;
  height: 48px;
  padding: 0 16px !important;
  min-width: 48px !important;
  min-height: auto;
  border-radius: 24px !important;
}

.Albums-album-cover .MuiFab-primary {
  color: #fff !important;
  background-color: #3f51b5 !important;
}

.AlbumDetailsDialog-songs.MuiExpansionPanel-root.Mui-expanded {
  margin: 0 0 16px;
}

.AlbumDetailsDialog-songs .MuiExpansionPanelDetails-root {
  display: flex;
  padding: 0;
}
