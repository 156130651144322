.Breadcrumb-root {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.Breadcrumb-menu {
    flex-grow: 1;
}

.Breadcrumb-socialmedia {
    flex-grow: 1;
    text-align: right;
    min-width: 156px;
}