.Home-social-media {
  display: flex;
  flex-wrap: wrap;
}

.Home-media-link {
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 10px 10px 0;
}

.Home .MuiCardMedia-img {
    object-fit: contain;
}

.Home .MuiLink-underlineHover:hover {
    text-decoration: none;
}

.Home-news p {
    white-space: break-spaces;
}

.Home-news .MuiCardMedia-img {
    max-height: 50vh;
    object-position: top;
    object-fit: cover;
}

.Home .header-image .MuiCardMedia-img {
  object-fit: cover;
  object-position: left;
}

.Home .header-image img {
  height: 290px;
}
