.About-paragraph {
    display: flex;
    margin-bottom: 25px;
    flex-wrap: wrap;
}

.About-paragraph p {
    font-family: Georgia, 'Times New Roman', Times, serif !important;
    -webkit-font-smoothing: antialiased !important;
    flex-basis: 50%;
    flex-grow: 1;
}

.About-paragraph-image {
    margin-right: 25px;
    width: 100%;
    flex-basis: calc(50% - 26px);
}

.About-paragraph-image.right {
    margin-right: 0;
    margin-left: 25px;
    width: 100%;
}

.About-paragraph-image.center {
    margin-right: 0;
    margin-left: 0;
    width: 100%;
    flex-basis: 100%;
}

@media (max-width: 600px) {
    .About-paragraph-image {
        margin-right: 0;
        margin-left: 0;
        width: 100%;
        flex-basis: 100%;
    }

    .About-paragraph-image.right {
        margin-right: 0;
        margin-left: 0;
        width: 100%;
    }
    
    .About-paragraph-image.center {
        margin-right: 0;
        margin-left: 0;
        width: 100%;
        flex-basis: 100%;
    }

    .About-paragraph p {
        font-family: Georgia, 'Times New Roman', Times, serif !important;
        -webkit-font-smoothing: antialiased !important;
        flex-basis: 100%;
        flex-grow: 1;
    }
}