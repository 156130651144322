@media (max-width: 600px) {
    .MuiContainer-root {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.RootContainer-root {
    display: flex;
    flex-direction: column;
}

.RootContainer-header {
    font-family: Arial Narrow, Arial, sans-serif;
    height: 150px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.RootContainer-container {
    display: flex;
}

.RootContainer-bottom {
    display: flex;
    margin-top: 20px;
    height: 50px;
    justify-content: center;
    align-items: center;
}

.RootContainer-logo {
    background-size: cover;
    background-image: url('../../img/logo.png');
    width: 150px;
    height: 150px;
}

.RootContainer-pages {
    background-color: rgb(207, 232, 252);
    min-height: 70vh;
    flex-grow: 1;
    z-index: 1;
    padding: 16px;
    flex: auto;
}

.RootContainer-pages.MuiDrawer-paper {
    flex: unset;
}

.Pages-content {
    text-align: left;
    padding: 30px;
}

@media (max-width: 600px) {
    .Pages-content {
        text-align: left;
        padding: 30px 10px;
    }
}

.privacy-policy-link {
    color: rgba(0, 0, 0, 0.87);
    margin-left: 0.5rem;
}