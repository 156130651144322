
.background-image {
  position: fixed;
  top: 0;
  left: 0;
  right: 16px;
  z-index: 1;
  display: block;
  background-size: cover;
  background-image: url('../img/folder.jpg');
  width: 110%;
  height: 110vh;
  -webkit-filter: blur(16px);
  -moz-filter: blur(16px);
  -o-filter: blur(16px);
  -ms-filter: blur(16px);
  filter: blur(16px);
}

.App .MuiDrawer-paper {
  position: unset !important;
  flex: auto;
}

#root {
  z-index: 3;
  left: 0;
  right: 0;
  height: 100%;
  overflow: auto;
  padding-bottom: 20px;
}

.App {
  position: relative;
  text-align: center;
  z-index: 99;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
